.Rs-content {
    font-weight: 700;
    font-size: 32px;
}

.icon-content {

    border-radius: 30px 0 0 0;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom{
    display: none;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
    display: none;
}

.pie-section div{
    display: flex;
    justify-content: center;
}

.main-content {
    box-shadow: 0px 5px 30px 0px #0000000D
}

.content-balance {
    font-size: 18px;
    padding: 32px 72px 33px 16px;
}

.review-section {
    padding: 20px 0px;
    border-bottom: 1px solid #d0c8c8;
}

.pie-section {
    padding: 0px 100px;
}

.support-content {
    padding: 20px 30px;
}

.fileSection img {
    width: 10px;
}

.fileSection {
    background-color: yellow;
    border-radius: 50%;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}



.Submit {
    outline: none;
    background-color: #ED839A;
    font-size: 12px;
    padding: 10px 40px;
    border-radius: 35px;
}

.css-1yhpg23-MuiTableCell-root {
    font-weight: 600 !important;
}




@media only screen and (max-width: 763px) {
    .content-section {
        padding: 30px 20px;
    }

    .pie-section {
        overflow: auto;
        padding: 0px;
    }

    .content-graph {
        overflow: scroll;
    }

    .content-graph-text {
        display: none;
    }
}

@media only screen and (max-width: 693px) {
    .content-section {
        padding: 30px 10px;
    }
}