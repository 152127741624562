@tailwind base;
@tailwind components;
@tailwind utilities;


/* ::-webkit-scrollbar {
    display: none;
  } */


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@400;500;700&family=Rubik+Gemstones&family=Rubik:wght@400;500&display=swap');

/* @media all and (max-width: 500px){
    ::-webkit-scrollbar {
      
    }
  } */
